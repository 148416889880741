import {
    Box,
    Button
} from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import logo from '../../images/logo/linguo-logo-white.png';
import { openSignInModal } from '../../redux/actions';
import DummySearchbox from '../Search/DummySearchbox';
import Link from 'next/link';

const mapStateToProps = state => {
    return {
        userData: state.requestUserData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onOpenSignIn: () => dispatch(openSignInModal())
    }
}

export const LogoImg = (props) => (
    <Box onClick={props.onClick} width={props.width} height={props.height} {...props} position="relative">
        <Image
        src={logo}
        layout="fill"
        objectFit="contain"
        />
    </Box>
)

const Navbar = ({ onOpenSignIn, userData }) => {
    const router = useRouter();
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box id="navbar-main" px={3} pt={1.6} pb={1.6} w="100%" display="flex" flexDirection="row" justifyContent="space-between" sx={{
            display: {
                xs: 'block',
                md: 'flex',
                position: 'fixed',
                top: 0,
                width: '100%',
                background: '#099A9D',
                zIndex: 1,
                transition: 'box-shadow 0.2s',
                boxShadow: scrollPosition > 0?'0px 1px 10px #999':'unset',
                mb: { xs: 35, md: 15 }
            }
        }}>
            <Box display="grid" sx={{ 
                placeItems: { xs: 'center', sm: 'center' },
                width: { xs: '100%', md: 'unset' }
            }}>
                <Link href={"/"}>
                    <LogoImg
                    width="176px"
                    height="55px"
                    sx={{
                        width: { xs: '120px', sm: '176px' },
                        cursor: 'pointer',
                        transition: 'opacity 0.2s',
                        '&:hover': {
                            opacity: '70%'
                        }
                    }}
                    />
                </Link>
            </Box>
            <Box sx={{
                mt: { xs: 3, md: 'unset' },
                display: { xs: 'flex', md: 'inline-flex' },
                flexDirection: { xs: 'column', md: 'unset' },
                width: { xs: '100%', md: 'unset' },
                justifyContent: { xs: 'center', md: 'unset' }
            }}>
                <DummySearchbox/>
                <Button 
                onClick={onOpenSignIn}
                sx={{ 
                    ml: { xs: 'unset', md: 2 }, 
                    width: { xs: 200, md: 'unset' },
                    alignSelf: { xs: 'center', md: 'unset' },
                    px: 5, 
                    borderRadius: 10, 
                    backgroundColor: 'secondary.main', 
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'secondary.dark'
                    }
                }}>
                    {userData.isAuthenticated ? 'Log out':'Log in'}
                </Button>
            </Box>
        </Box>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);