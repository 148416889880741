import { Box, Button, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Tag from "../../Tags/Tag";
import { readingTime } from "../../../utils";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCategory, unselectCategory } from "../../../redux/actions";
import { useRouter } from "next/router";

const imageStyles = {
  width: "100%",
  height: "100%",
  position: "absolute",
  objectFit: "cover",
};

const authorTextStyles = {
  fontFamily: "Poppins, sans-serif",
  fontWeight: 500,
  fontSize: "0.75rem",
  letterSpacing: "-0.01em",
  fontStyle: "normal",
  lineHeight: "161.5%",
};

const authorCaptionStyles = {
  display: "block",
  color: "#B3B3B5",
  fontFamily: "Poppins, sans-serif",
  fontWeight: 500,
  position: "relative",
  lineHeight: "181.5%",
  letterSpacing: "-0.01em",
  mt: "3%",
  fontSize: 14,
};

// const zoom = 'zoom 0.7s infinite alternate';
// animation: isHovered?zoom:'unset',
// WebkitAnimation: isHovered?zoom:'unset',
// MozAnimation: isHovered?zoom:'unset',

export const Headline = ({
  title,
  category,
  caption,
  image,
  onClick,
  authorName,
  authorImage,
  createdAt,
  textCount,
  dataAOS,
  delay,
}) => {
  const Router = useRouter();
  const readTime = readingTime(textCount);
  const [isHovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  const selectedTag = useSelector((state) => state.selectedCategory.selected);
  const minuteText = readTime > 1 ? " mins" : " min";

  const onClickCategory = (selectedTag, newTag) => {
    if (window.location.pathname === "/") {
      selectedTag === newTag
        ? dispatch(unselectCategory())
        : dispatch(selectCategory(newTag));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      dispatch(selectCategory(newTag));
      Router.push("/");
    }
  };

  return (
    <Box
      data-aos={dataAOS}
      data-aos-delay={delay}
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" },
        gap: 5,
        alignItems: "center",
      }}
    >
      {image?.options?.src && (
        <Box
          onClick={onClick}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          sx={{
            width: "100%",
            height: { xs: "300px", md: "100%" },
            borderRadius: 23,
            position: "relative",
            cursor: "pointer",
          }}
        >
          <img
            alt={title}
            style={{
              ...imageStyles,
              transition: "filter 0.25s, transform 0.4s ease",
              filter: isHovered
                ? "drop-shadow(2px 4px 6px lightblue)"
                : "unset",
              borderRadius: 23,
            }}
            src={image}
          />
        </Box>
      )}
      <Box sx={{ height: "max-content" }}>
        {category && (
          <Box display="flex" alignItems="center">
            <Tag onClick={() => onClickCategory(selectedTag, category)}>
              {category}
            </Tag>
            <Typography
              sx={{
                ml: 1,
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: 13,
              }}
            >
              {"• " + readTime + minuteText}
            </Typography>
          </Box>
        )}
        <Typography
          onClick={onClick}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          sx={{
            textAlign: "left",
            cursor: "pointer",
            fontSize: isHovered ? "2.3em" : "2em",
            transition: "font-size 0.25s",
            mt: 1,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            ...authorCaptionStyles,
            textAlign: "justify",
          }}
          variant="body2"
        >
          {caption.substr(0, 300) + "..."}
        </Typography>
        <ReadMoreButton onClick={onClick} />
        <AuthorComponent
          authorName={authorName}
          createdAt={createdAt}
          authorImage={authorImage}
        />
      </Box>
    </Box>
  );
};

export const Showcase = ({
  title,
  category,
  caption,
  image,
  onClick,
  authorName,
  authorImage,
  createdAt,
  dataAOS,
  delay,
}) => {
  const Router = useRouter();
  const [isHovered, setHovered] = useState(false);
  const dispatch = useDispatch();
  const selectedTag = useSelector((state) => state.selectedCategory.selected);

  const onClickCategory = (selectedTag, newTag) => {
    if (window.location.pathname === "/") {
      selectedTag === newTag
        ? dispatch(unselectCategory())
        : dispatch(selectCategory(newTag));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      dispatch(selectCategory(newTag));
      Router.push("/");
    }
  };
  return (
    <Box
      data-aos={dataAOS}
      data-aos-delay={delay}
      display="flex"
      flexDirection="column"
    >
      {image?.options?.src && (
        <Box
          onClick={onClick}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          sx={{
            width: "100%",
            height: 300,
            position: "relative",
          }}
        >
          <img
            alt={title}
            style={{
              ...imageStyles,
              transition: "filter 0.25s",
              filter: isHovered
                ? "drop-shadow(2px 4px 6px lightblue)"
                : "unset",
              borderRadius: 23,
            }}
            src={image}
          />
        </Box>
      )}
      {category && (
        <Box sx={{ mt: 2 }}>
          <Tag onClick={() => onClickCategory(selectedTag, category)}>
            {category}
          </Tag>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Typography
          onClick={onClick}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          sx={{
            fontFamily: "SkModernist",
            fontWeight: 700,
            lineHeight: "120%",
            letterSpacing: "-0.01em",
            cursor: "pointer",
            fontSize: isHovered ? "1.55rem" : "1.25rem",
            transition: "font-size 0.25s",
            mt: 1,
            width: "100%",
          }}
        >
          {title.length > 40 ? `${title.substring(0, 40)}..` : title}
        </Typography>
        <Typography
          sx={{
            ...authorCaptionStyles,
          }}
        >
          {caption.substr(0, 50) + "..."}
        </Typography>
        <ReadMoreButton onClick={onClick} />
        <AuthorComponent
          authorName={authorName ? authorName : null}
          createdAt={createdAt}
          authorImage={authorImage ? authorImage : null}
        />
      </Box>
    </Box>
  );
};

export const AuthorComponent = ({
  authorName,
  createdAt,
  authorImage,
  noMargin,
}) => {
  if (authorName && createdAt && authorImage) {
    return (
      <Box
        mt={noMargin ? 0 : 2}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box
          sx={{
            width: 33,
            height: 33,
            position: "relative",
          }}
        >
          <img
            src={authorImage}
            style={{
              ...imageStyles,
              borderRadius: 9,
            }}
          />
        </Box>
        <Box sx={{ ml: 1 }}>
          <Typography
            sx={{
              ...authorTextStyles,
              color: "black",
            }}
          >
            By: {authorName}
          </Typography>
          <Typography
            sx={{
              ...authorTextStyles,
              color: "#B3B3B5",
              fontSize: 11,
            }}
          >
            {createdAt}
          </Typography>
        </Box>
      </Box>
    );
  } else return null;
};

export const ReadMoreButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    variant="contained"
    sx={{ mt: 1, maxWidth: "max-content" }}
    endIcon={<ArrowRightAltIcon />}
  >
    Read More
  </Button>
);

export const ArticleListItem = ({
  title,
  image,
  category,
  caption,
  onClick,
  textCount,
}) => {
  const readTime = readingTime(textCount);
  const minuteText = readTime > 1 ? " mins" : " min";

  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        my: 1.5,
        transition: "all 0.3s",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0px 0px 8px 2px rgba( 0, 0, 0, 0.2 )",
          border: "3px solid lightblue",
          borderRadius: 5,
          p: { xs: 0, sm: 1.5 },
        },
      }}
    >
      {image?.options?.src && (
        <Box
          sx={{
            position: "relative",
            height: 97,
            width: 92,
          }}
        >
          <img src={image} style={{ ...imageStyles, borderRadius: 23 }} />
        </Box>
      )}
      <Box
        sx={{
          height: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box sx={{ height: "max-content", width: "auto", ml: 1.5 }}>
          {category && (
            <Box display="flex" alignItems="center">
              <Tag px={1.3} fontSize="11px">
                {category}
              </Tag>
              <Typography
                sx={{
                  ml: 1,
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 500,
                  fontSize: 10,
                }}
              >
                {"• " + readTime + minuteText}
              </Typography>
            </Box>
          )}
          <Typography
            sx={{
              fontFamily: "SkModernist",
              fontWeight: 700,
              fontSize: 17,
              lineHeight: "120%",
              letterSpacing: "-0.01em",
              width: "90%",
            }}
          >
            {title.length > 30 ? `${title.substr(0, 30)}...` : title}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
