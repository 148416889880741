import {
    InputBase,
    Box,
    Button,
    Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';
import { handleClickSearch } from '../../redux/actions';

const mapStateToProps = state => {
    return {
        isOpen: state.searchModal.isOpen,
        value: state.searchModal.value
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onClickSearchBox: (state) => dispatch(handleClickSearch(state))
    }
}

const DummySearchbox = ({ isOpen, onClickSearchBox, value }) => {
    return (
        <Box
        component="form"
        onClick={() => onClickSearchBox(isOpen)} 
        elevation={1}
        sx={{
            p: 1,
            borderRadius: 4,
            display: 'flex',
            height: { xs: 49, md: 'unset' }, 
            alignItems: 'center',
            alignSelf: 'center',
            mb: { xs: 3, md: 'unset' },
            backgroundColor: '#F3F5F7',
            width: 300,
            transition: 'all 0.2s',
            cursor: 'pointer',
            '&:hover': {
                boxShadow: '0px 0px 8px 2px rgba( 0, 0, 0, 0.2 )',
                border: '3px solid lightblue'
            }
        }}
        >
            <SearchIcon sx={{ opacity: '40%' }}/>
            <Typography
            sx={{
                ml: 1,
                flex: 1,
                fontFamily: 'SkModernist',
                fontWeight: 'bold',
                fontSize: '0.95rem',
                color: value?'black':'#C0CDDA'
            }}
            >
                {value?value:'Search'}
            </Typography>
            <Button sx={{ 
                background: '#61A0DC',
                color: 'white',
                fontFamily: 'SkModernist',
                fontWeight: 'bold',
                borderRadius: 3,
                '&:hover': {
                    background: '#3470AA'
                }
            }}>
                Go
            </Button>
        </Box>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DummySearchbox);
