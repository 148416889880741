import {
    Box,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import {
    AiFillInstagram,
    AiFillFacebook,
    AiFillYoutube,
    AiOutlineTwitter,
    AiFillLinkedin
} from 'react-icons/ai';
import {
    IoLogoTiktok
} from 'react-icons/io5';
import { returnApiMain } from '../../utils';

const styles = {
    root: {
        mt: 12,
        width: '100%',
        px: { xs: '10%', sm: '5%', md: '7%', lg: '20%' },
        py: 10,
        display: 'grid',
        placeItems: 'baseline',
        background: 'rgba(0, 154, 157, 0.0585698)',
        paddingLeft: '15%'
    },
    box: {
        width: { xs: '100%', md: '80%', lg: '70%', xl: '60%' },
        display: 'grid',
        gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)'},
        gap: 3
    },
    heading: {
        fontSize: 15,
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 600
    },
    item: {
        fontSize: 13,
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 500,
        width: 'max-content',
        mt: 1,
        flex: 'none',
        order: '6',
        flexGrow: 0,
        position: 'relative',
    },
    itemNav: {
        cursor: 'pointer',
        '&:after': {
            content: "''",
            position: 'absolute',
            width: '100%',
            transform: 'scaleX(0)',
            height: '2.5px',
            bottom: '-2px',
            left: 0,
            backgroundColor: '#0485aa',
            transformOrigin: 'bottom right',
            transition: 'transform 0.25s ease-out'
        },
        '&:hover:after': {
            transform: 'scaleX(1)',
            transformOrigin: 'bottom left'
        }
    },
    iconbtn: {
        mx: 0.2,
        background: '#F3F5F8'
    }
}

const Footer = () => {
    return (
        <Box sx={styles.root}>
            <Box sx={styles.box}>
                <FooterItem
                items={LANGUAGE_ITEMS}
                heading="Learn a Language"
                extra="Learn"
                />
                <Stack direction="column" spacing={4}>
                    <FooterItem
                    items={LEVEL_ITEMS}
                    heading="Level options"
                    />
                    <FooterItem
                    items={PROGRAM_ITEMS}
                    heading="Program"
                    extra="Class"
                    />
                </Stack>
                <Box>
                    <Typography sx={styles.heading}>
                        Teaching
                    </Typography>
                    <Typography sx={{ ...styles.item, mt: 1.5 }}>
                        Become a teacher
                    </Typography>
                    <Typography sx={{ ...styles.item, mt: 3.5, lineHeight: '165%', cursor: 'default' }}>
                        Alamat: Happy Creative Hub, 
                        <br/>
                        Jl. Cisitu Indah III No.2, Dago, Coblong, 
                        <br/>
                        Bandung City, West Java 40135
                        <br/>
                        Telepon : (022)85942550
                        <br/>
                        Email : info@linguo.id
                    </Typography>
                    <Box mt={2} display="flex" flexDirection="row">
                    { SOCIAL_ICONS.map((item, i) => (
                        <IconButton
                        onClick={() => window.open(item.url)}
                        sx={styles.iconbtn}
                        key={i}>
                            {item.icon}
                        </IconButton>
                    ))}
                    </Box>
                    <Typography sx={{...styles.item, fontSize: 12, mt: 5}}>
                        © 2021 PT. Linguo Edu Indonesia
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
};

const FooterItem = ({ items, heading, extra }) => {
    return (
        <Box sx={{ display: { xs: 'none', sm: 'unset' }}}>
            <Typography sx={styles.heading}>{heading}</Typography>
            <Box sx={{ mt: 2 }}>
                { items.map((item, i) => {
                    const params = extra === 'Learn' ? 'language' : extra === 'Class' ? 'class' : 'level';
                    return (
                        <Typography onClick={() => window.open(`${returnApiMain()}/${params}/${item.label}`)} sx={{...styles.item, ...styles.itemNav}} key={i}>
                            {extra === 'Learn'?
                            "Learn "+item.label
                            :
                            extra === 'Class'?
                            item.label+" Class"
                            :
                            item.label
                        }
                        </Typography>
                    )
                })}
            </Box>
        </Box>
    )
};

const LANGUAGE_ITEMS = [
    { label: 'English', url: '' },
    { label: 'French' , url: '' },
    { label: 'Spanish' , url: '' },
    { label: 'Portuguese' , url: '' },
    { label: 'German' , url: '' },
    { label: 'Japanese' , url: '' },
    { label: 'Korean' , url: '' },
    { label: 'Arabic' , url: '' },
    { label: 'Hindi' , url: '' },
    { label: 'Italian' , url: '' },
    { label: 'Russian', url: '' },
    { label: 'More Languages' , url: '' }
];

const LEVEL_ITEMS = [
    { label: 'Basic', url: '' },
    { label: 'Upper Basic', url: '' },
    { label: 'Intermediate', url: '' },
    { label: 'Advanced', url: '' }
];

const PROGRAM_ITEMS = [
    { label: 'Regular', url: '' },
    { label: 'Private', url: '' },
    { label: 'IELTS', url: '' },
    { label: 'TOEFL', url: '' },
];

const SOCIAL_ICONS = [
    { icon: <AiFillLinkedin size={15} color="#009A9D"/>, url: 'https://www.linkedin.com/in/admin-linguo-a99051218/'},
    { icon: <IoLogoTiktok size={15} color="#009A9D"/>, url: 'https://www.tiktok.com/@linguoid?lang=en'},
    { icon: <AiFillFacebook size={15} color="#009A9D"/>, url: 'https://m.facebook.com/linguo.id/?__nodl&refsrc=deprecated&ref=external%3Awww.google.com&_rdr'},
    { icon: <AiFillYoutube size={15} color="#009A9D"/>, url: 'https://www.youtube.com/channel/UCqHqSHoe0IZcRzyBlXR0yeQ'},
    { icon: <AiFillInstagram size={15} color="#009A9D"/>, url: 'https://www.instagram.com/linguo.id/'},
    { icon: <AiOutlineTwitter size={15} color="#009A9D"/>, url: 'https://twitter.com/linguo_id' }
];


export default Footer;