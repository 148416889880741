import {
    Box,
    Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { selectCategory, unselectCategory } from '../../redux/actions';
import Tag from '../Tags/Tag';
import { useRouter } from 'next/router';

const mapStateToProps = state => {
    return {
        selectedTag: state.selectedCategory.selected
    }
}

const mapDispatchToProps = dispatch => {
    const router = useRouter();
    return {
        onClickCategory: (selectedTag, newTag) => {
            if (window.location.pathname === '/') {
                selectedTag === newTag?
                dispatch(unselectCategory())
                :
                dispatch(selectCategory(newTag));
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            } else {
                dispatch(selectCategory(newTag));
                router.push('/');
            }
        },
    }
}

const CategoryFilter = ({ onClickCategory, selectedTag }) => {
    return (
        <Box py={2} display="flex" flexDirection="column" alignItems="center">
            <Typography sx={{ m: 2, fontWeight: 500, fontFamily: 'Poppins, sans-serif', fontSize: 14 }}>
                Searching on categories
            </Typography>
            <Box width="45%" display="flex" sx={{ flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center' }} justifyContent="space-evenly">
                { TAGS.map((tag, index) => (
                    <Tag opacity={selectedTag === tag?'100%':'55%'} onClick={() => onClickCategory(selectedTag, tag)} key={index}> 
                        {tag}
                    </Tag>
                ))}
            </Box>
        </Box>
    )
}

const TAGS = [ 'Tips', 'Edukasi', 'Fun', 'Grammar' ];

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFilter);